import React from 'react'
import PropTypes from 'prop-types'
import { Card, CardHeader } from '@material-ui/core'
import styles from './card.module.css'
import utilStyles from '../styles/utils.module.css'

export const CustomCard = ({ title, time, text, ...props }) => {
  return (
    <Card
      classes={{root: styles.root}}
      variant='elevation'>
      
      <CardHeader
        classes={{ root: utilStyles.headingXl }}
        title={title}
        subHeader='subHeader' />
      
      <p className={utilStyles.lightText}>{time}</p>
      <p>{text}</p>
    </Card>
  )
}

export default CustomCard

CustomCard.propTypes = {
  /**
   * Title text of card
   */
  title: PropTypes.string,

  /**
   * The time to display
   */
  time: PropTypes.string,

  /**
   * The main text body
   */
  text: PropTypes.string
}

CustomCard.defaultProps = {
  title: 'Default Title',
  time: '00:00',
  text: 'Default Text'
}